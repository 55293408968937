import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';
import { InputText } from 'primereact/inputtext';

export class FormStatQtePrest extends Component {
    constructor() {
        super();
        this.state = {
            pd1:  new Date(moment(new Date()).format('YYYY') + '-01-01'),
            pd2: new Date(),
            rRegion: null,
            rPref: null,
            rCrd: null,
            rtypePrest: null,
            ridPrest: null,
            rlib: '',
            liste_data: [],
            liste_type_prest: [],
            listeprest: [],
            listeprest_all: [],
            listetypeserv_all: [],
            liste_region: [],
            liste_all_pref: [],
            liste_pref: [],
            liste_all_crd: [],
            liste_crd: [],
            items: [
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.FbrService = new FbrService();
    }


    get_liste_codif() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif, idTypePrest: x.typePrest.idCodif,regionSite: x.idSite.regionSite,prefectureSite: x.idSite.prefectureSite,crdSite: x.idSite.crdSite }
                    }).filter(function (el) {
                        return (el.typePrest == 'RECO-ASC' || el.typePrest == 'PS' ||el.typePrest == 'Hopital' ||el.typePrest == 'CS' ||el.typePrest == 'DPS'||el.typePrest == 'DRS');
                    });;
                    this.setState({ listeprest: liste,listeprest_all: liste });
                }
            }
        });
        this.FbrService.get_liste_codif('TYPE_PRESTATAIRE').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_type_prest: liste });
            }
        });
    }

    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
        this.FbrService.generer_stat_rapportage_prest(spd1, spd2, region, pref, crd, format);
    }
 
    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        let id_prest = 'Tous';
        let type_prest = 'Tous';
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
        if (this.state.rtypePrest !== undefined && this.state.rtypePrest !== null) {
            type_prest = this.state.rtypePrest;
        }
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            id_prest = this.state.ridPrest;
        }
        this.setState({ showindicator: true });
        this.FbrService.get_stat_quantite_prest(spd1, spd2, region, pref, crd, type_prest, id_prest, this.state.rlib).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    componentDidMount() {
        this.FbrService.get_liste_prefecture().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_pref: data });
            }
        });
        this.FbrService.get_liste_crd().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
            }
        });
        this.get_liste_codif();
        this.get_liste_data();
        this.setState({ liste_region: this.FbrService.get_liste_regions() });
    }


    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        this.setState({ liste_pref: liste });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        this.setState({ liste_crd: liste });
    }


    select_acteurs() {
        let liste = this.state.listeprest_all;
        if (this.state.listeprest_all != null && this.state.listeprest_all != undefined) {
            if (this.state.listeprest_all.length > 0) {
                let region=this.state.rRegion==null?'Tous':this.state.rRegion;
                let pref=this.state.rPref==null?'Tous':this.state.rPref;
                let crd=this.state.rCrd==null?'Tous':this.state.rCrd;
                let type_prest=this.state.rtypePrest==null?'Tous':this.state.rtypePrest;

                if(region!=='Tous'){
                    liste=liste.filter(function (el) {
                        return el.regionSite == region;
                    });
                }
                if(pref!=='Tous'){
                    liste= liste.filter(function (el) {
                        return el.prefectureSite == pref;
                    });
                }
                if(crd!=='Tous'){
                    liste= liste.filter(function (el) {
                        return el.crdSite == crd;
                    });
                }
                if(type_prest!=='Tous'){
                    liste= liste.filter(function (el) {
                        return el.idTypePrest == type_prest;
                    });
                }
                
            }
        }
        this.setState({ listeprest: liste });
    }


    render() {
        return (
            <div className="card p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                            <hr />
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'Region', value: 'Tous' }].concat(this.state.liste_region)} optionLabel='label' placeholder='Region' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rRegion} onChange={e => this.setState({ rRegion: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_pref(e.value);
                                        this.select_acteurs();
                                    })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'Préfecture', value: 'Tous' }].concat(this.state.liste_pref)} optionLabel='label' placeholder='Préfecture' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rPref} onChange={e => this.setState({ rPref: e.value }, () => {
                                        this.get_liste_data();
                                        this.setState({ liste_crd: [] });
                                        this.select_crd(e.value);
                                        this.select_acteurs();
                                    })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'CR', value: 'Tous' }].concat(this.state.liste_crd)} optionLabel='label' placeholder='CR' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rCrd} onChange={e => this.setState({ rCrd: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_acteurs();
                                    })} />
                                </div>
                                <div className="col-2 md:col-3">
                                    <Dropdown autoWidth={false} options={this.state.liste_type_prest} optionLabel='label' placeholder='Type Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rtypePrest} onChange={e => this.setState({ rtypePrest: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_acteurs();
                                    })} panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-2 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ value: 'Tous', label: 'Acteur' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                        this.get_liste_data();
                                    })} panelStyle={{ width: '500px' }} />
                                </div>
                               
                                <div className="col-12 md:col-3">
                                    <InputText placeholder="Indicateur" value={this.state.rlib} onChange={(e) => this.setState({ rlib: e.target.value })} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <center>
                    <SplitButton label="Rechercher" style={{ width: '170px', marginBottom: 5 }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                </center>

                <DataTable value={this.state.liste_data} responsive={true} scrollable={true} scrollHeight="620px" csvSeparator=";"  ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column field="ordre" header="#" style={{ width: '5%' }} filter={true} filterMatchMode='contains' />
                    <Column field="libLobj" header="Indicateur" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="valPrevLobj" body={(rowData, column) => {
                        return <div>
                            <span >{this.FbrService.formaterValueSep(parseFloat(rowData.valPrevLobj))}</span>
                        </div>
                    }} header="CA" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="valDhis2Lobj" body={(rowData, column) => {
                        return <div>
                            <span >{this.FbrService.formaterValueSep(parseFloat(rowData.valDhis2Lobj))}</span>
                        </div>
                    }} header="SNIS" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="valEvalLobj" body={(rowData, column) => {
                        return <div>
                            <span >{this.FbrService.formaterValueSep(parseFloat(rowData.valEvalLobj))}</span>
                        </div>
                    }} header="QD" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="valConfLobj" body={(rowData, column) => {
                        return <div>
                            <span >{this.FbrService.formaterValueSep(parseFloat(rowData.valConfLobj))}</span>
                        </div>
                    }} header="QV" style={{ width: '7%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="montantLobj" header="Montant" body={(rowData, column) => {
                        return <div>
                            <span style={{ fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.montantLobj))}</span>
                        </div>
                    }} sortable={true} style={{ width: '13%' }} filter={true} filterMatchMode='contains'  />

                </DataTable>
            </div>
        );
    }
}