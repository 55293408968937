import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Appservice from '../service/FbrService';

export class Login extends Component {
    constructor() {
        super();
        this.state = {
            showindicator: false,
            login: '',
            pwd: '',
            msg: '',
        };
        this.appservice = new Appservice();
    }

    handleKeyPress = e => {
        if (e.keyCode === 13) {
            this.identification();
        }
    }



    identification() {
        if (this.state.login == '' || this.state.pwd == '') {
            this.setState({ msg: 'Veuilllez saisir le login et le mot de passe' });
        } else {
            this.setState({ showindicator: true });
            this.appservice.get_identification(this.state.login, this.state.pwd).then(data => {
                if (data !== null && data != undefined && data !== '') {
                    if (data.actifUser === 'Oui') {
                        this.appservice.get_liste_region().then(regions => {
                            if (regions !== null && regions != undefined && regions !== '') {
                                localStorage.setItem('regions', JSON.stringify(regions));
                            }
                        });
                        localStorage.setItem('userconnected', JSON.stringify(data));
                        window.location = "#/dashboard";
                        this.setState({ showindicator: false });
                    } else {
                        this.setState({ showindicator: false });
                        this.setState({ msg: "Vous n'etes pas autorisés à vous connecter, merci de contacter l'administrateur " });
                    }
                } else {
                    this.setState({ showindicator: false });
                    this.setState({ msg: 'Login ou mot de passe incorrects' });
                }
            });
        }
    }

 
    render() { 
        return (
            <div className="login-body">
                <div className="login-wrapper">
                    <div className="login-panel" >
                        {/* <img src="assets/layout/images/logoLogin.png" className="logo" alt="diamond-layout" style={{ height: 150, width: 320 }} /> */}
                         <img src="assets/layout/images/logo-fbr.png" className="logo" alt="diamond-layout" style={{ height: 100, width: 110 }} /> 
                        {/* <img src="assets/layout/images/logoEnabel.jpg" className="logo" alt="diamond-layout" style={{ height: 100, width: 220 }} /> */}

                        <div className="login-form" style={{ marginTop: 80 }}>
                            <h2>Connexion</h2>
                            <h4>Pour vous connecter utiliser vos accès</h4>
                            <InputText placeholder="Votre login" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ login: e.target.value })} value={this.state.login} style={{ width: '90%' }} />
                            <Password feedback={false} placeholder="Votre mot de passe" onKeyDown={this.handleKeyPress} onChange={(e) => this.setState({ pwd: e.target.value })} value={this.state.pwd} style={{ width: '90%' }} />
                            <Button label="Connexion" icon="pi pi-sign-in" type="button" style={{ width: 150 }} onClick={(e) => this.identification()}></Button>
                            <br />
                            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px', marginTop: '10px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            <br />
                            <label style={{ color: 'red', marginTop: 10, fontWeight: 'bold' }} >{this.state.msg}</label>
                        </div>
                    </div>
                    <div className="login-image">
                        <div className="login-image-content">
                            <h1 style={{ color: 'yellow', fontWeight: 'bold', marginTop: '50%' }}></h1>
                            <h3>
                            </h3>
                        </div>
                        <div className="image-footer">

                         {/*  <p style={{ color: '#fff' }}>
                                <div className="p-grid" style={{ width: 300,marginBottom: -40,marginLeft: -300 }}>
                                    <div className="p-col">
                                        <img src="assets/layout/images/logoKFW.png"  style={{ height: 70, width: 120 }} />
                                    </div>
                                    <div className="p-col">
                                        <img src="assets/layout/images/logoCS.png"  style={{ height: 70, width: 120 }} />
                                    </div>
                                </div>
                            </p> */}
 
                           
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
