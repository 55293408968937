import React from 'react';
import { classNames } from 'primereact/utils';
import { Link } from 'react-router-dom';
import AppBreadcrumb from './AppBreadcrumb';
import AppMenu from './AppMenu';
import FbrService from './service/FbrService';

const AppTopbar = (props) => {

    const notificationsItemClassName = classNames('notifications-item', { 'active-menuitem': props.topbarNotificationMenuActive });
    const profileItemClassName = classNames('profile-item', { 'active-menuitem fadeInDown': props.topbarUserMenuActive });

    const appservice = new FbrService();
    var userconnected = appservice.get_userconnected();


    var nom_user = '';
    if (userconnected !== null && userconnected !== undefined) {
        nom_user = userconnected.idPrest.nomPrest;
    }

    return (
        <div className="layout-topbar">
            <div className="topbar-left">
                <button type="button" className="menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-left"></i>
                </button>

                <Link to="/">
                    <img id="logo-horizontal" className="horizontal-logo" src="assets/layout/images/logo-white.svg" alt="diamond-layout" />
                </Link>

                <span className="topbar-separator"></span>

                <div className="layout-breadcrumb viewname" style={{ textTransform: 'uppercase' }}>
                    <AppBreadcrumb routers={props.routers} />
                </div>

                <img id="logo-mobile" className="mobile-logo" src="assets/layout/images/logo-dark.svg" alt="diamond-layout" />
            </div>

            <AppMenu model={props.menu} menuMode={props.menuMode} active={props.menuActive} mobileMenuActive={props.staticMenuMobileActive}
                onMenuClick={props.onMenuClick} onMenuitemClick={props.onMenuitemClick} onRootMenuitemClick={props.onRootMenuitemClick}></AppMenu>

            <div className="layout-mask modal-in"></div>

            <div className="topbar-right">
                <ul className="topbar-menu">
                    <li className="search-item">
                        <button type="button" className="p-link" onClick={props.onSearchClick}>
                            <i className="pi pi-search"></i>
                        </button>
                    </li>
                    <li className={notificationsItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarNotification}>
                            <i className="pi pi-bell"></i>
                            <span className="topbar-badge">5</span>
                        </button>
                    </li>

                    <li className={profileItemClassName}>
                        <button type="button" className="p-link" onClick={props.onTopbarUserMenu}>
                            <img src="assets/demo/images/avatar/profile.png" alt="diamond-layout" className="profile-image" />
                            <span className="profile-name">{nom_user}</span>
                        </button>
                        <ul className="profile-menu fade-in-up">
                            <li>
                                <button type="button" className="p-link" onClick={(e) => {
                                    window.location = "#/chpwd";
                                }}>
                                    <i className="pi pi-cog"></i>
                                    <span>Changer mon mot de passe</span>
                                </button>
                            </li>

                            <li>
                                <button type="button" className="p-link" onClick={(e) => {
                                    localStorage.removeItem('userconnected');
                                    window.location = "#/login";
                                }}>
                                    <i className="pi pi-power-off"></i>
                                    <span>Se déconnecter</span>
                                </button>
                            </li>

                        </ul>
                    </li>

                    <li className="right-sidebar-item">
                        <button type="button" className="p-link" onClick={(e) => {
                            localStorage.removeItem('userconnected');
                            window.location = "#/login";
                        }}>
                            <i className="pi pi-sign-out"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default AppTopbar;
