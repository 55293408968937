import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';
 
export class FormStatRapportagePref extends Component {
    constructor() {
        super();
        this.state = {
            pd1:  new Date(moment(new Date()).format('YYYY') + '-01-01'),
            pd2: new Date(),
            rRegion: null,
            liste_data: [],
            liste_region: [],
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.FbrService = new FbrService();
    }


    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
       
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }

        this.FbrService.generer_stat_rapportage_pref(spd1, spd2, region, format);
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
        
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        this.setState({showindicator: true});
        this.FbrService.get_stat_rapportage_pref(spd1, spd2, region).then(data => this.setState({ liste_data: data,showindicator: false }));
    }

    componentDidMount() {
        this.get_liste_data();
        this.setState({liste_region: this.FbrService.get_liste_regions()});
    }

    render() {
        return (
            <div className="card p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                                    <hr />
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'IRS', value: 'Tous' }].concat(this.state.liste_region)} optionLabel='label' placeholder='IRS' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rRegion} onChange={e => this.setState({ rRegion: e.value }, () => {
                                        this.get_liste_data();
                                    })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <div className="inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                    </div>
                                </div>
                                <div className="col-12 md:col-2">
                                    <div className="inputgroup">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DataTable value={this.state.liste_data} responsive={true} scrollable={true} scrollHeight="620px" csvSeparator=";"  ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column field="ordre" header="#" style={{ width: '4%' }} filter={true} filterMatchMode='contains'/>
                    <Column field="prefectureSite" header="DPS" filter={true} filterMatchMode='contains' sortable={true}  />
                    <Column field="regionSite" header="IRS" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '13%' }} />
                    <Column field="nb_prest" header="NB" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_prev" header="PREVU" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb" header="RAP" style={{ width: '9%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="taux_rp" body={(rowData, column) => {
                        return <div>
                            <span>{rowData.taux_rp} %</span>
                        </div>
                    }}  header="TAUX" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_val" header="VAL" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_ec" header="EC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_pay" header="PAYE" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            </div>
        );
    }
}