import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';
 
export class FormStatRapportageRegion extends Component {
    constructor() {
        super();
        this.state = {
            pd1:  new Date(moment(new Date()).format('YYYY') + '-01-01'),
            pd2: new Date(),
            liste_data: [],
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.FbrService = new FbrService();
    }


    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        this.FbrService.generer_stat_rapportage_region(spd1, spd2, format);
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        this.setState({ showindicator: true });
        this.FbrService.get_stat_rapportage_region(spd1, spd2).then(data => this.setState({ liste_data: data, showindicator: false }));
    }

    componentDidMount() {
        this.get_liste_data();
    }

    render() {
        return (
            <div className="card p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                            <hr />
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-2">
                                    <div className="inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '180px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                    </div>
                                </div>
                                <div className="col-12 md:col-2">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <DataTable value={this.state.liste_data} responsive={true} scrollable={true} scrollHeight="620px" csvSeparator=";"   ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column field="ordre" header="#" style={{ width: '5%' }} filter={true} filterMatchMode='contains'/>
                    <Column field="regionSite" header="IRS" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_prest" header="NB" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_prev" header="PREVU" style={{ width: '11%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb" header="RAP" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="taux_rp" header="TAUX" body={(rowData, column) => {
                        return <div>
                            <span>{rowData.taux_rp} %</span>
                        </div>
                    }} style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_val" header="VAL" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_ec" header="EC" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="nb_pay" header="PAYE" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            </div>
        );
    }
}