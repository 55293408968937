import Numeral from 'numeral';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';

export default class FbrService {
    constructor() {
        this.state = {
            //wsurl: 'http://localhost:7777',
            //wsurl: 'https://opt-api224.com/ws_fbr',
            wsurl: 'https://fbrguinee.sante.gov.gn/ws_fbr',

            liste_mois: [
                { label: 'Janvier', value: '01', abv: 'JAN' },
                { label: 'Février', value: '02', abv: 'FEV' },
                { label: 'Mars', value: '03', abv: 'MAR' },
                { label: 'Avril', value: '04', abv: 'AVR' },
                { label: 'Mai', value: '05', abv: 'MAI' },
                { label: 'Juin', value: '06', abv: 'JUIN' },
                { label: 'Juillet', value: '07', abv: 'JUIL' },
                { label: 'Aout', value: '08', abv: 'AOUT' },
                { label: 'Septembre', value: '09', abv: 'SEP' },
                { label: 'Octobre', value: '10', abv: 'OCT' },
                { label: 'Novembre', value: '11', abv: 'NOV' },
                { label: 'Décembre', value: '12', abv: 'DEC' }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            liste_region: JSON.parse(localStorage.getItem('regions')),
        };
    }

    //Vérifier si application TD
    checkTD(nbMois) {
        let bailleur = this.getBailleur();
        let apply = false;
        if (bailleur == 'ENABEL') {
            if (nbMois > 6) {
                apply = true;
            }
        } else {
            apply = true;
        }
        return apply;
    }

    // Calculer le taux de divergence
    getTD(rowData, nbMoisObj) {
        let marge = 0;
        /*
        if (rowData.idType.categorieType == 'Qualitative') {
            if (rowData.maxLobj == 0) {
                marge = 0;
            } else {
                marge = 100 * (rowData.maxLobj - rowData.valConfLobj) / rowData.maxLobj
            }
        } else if (rowData.idType.categorieType == 'Quantitative') {
            if (rowData.valEvalLobj == 0) {
                marge = 0;
            } else {
                marge = 100 * (rowData.valEvalLobj - rowData.valConfLobj) / rowData.valEvalLobj
            }
        }
        */
        if (rowData.valEvalLobj == 0) {
            marge = 0;
        } else {
            marge = 100 * (rowData.valEvalLobj - rowData.valConfLobj) / rowData.valEvalLobj
        }
        marge = Math.abs(parseFloat(marge));
        marge = Math.round(marge, 2);
        return marge;
    }

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            if (!map.has(key)) {
                map.set(key, [item]);
            } else {
                map.get(key).push(item);
            }
        });
        return map;
    }


    getMonthDifference(startDate, endDate) {
        return (
            endDate.getMonth() -
            startDate.getMonth() +
            12 * (endDate.getFullYear() - startDate.getFullYear())
        );
    }

    getNbMois(rowData) {
        var today = moment('28/' + rowData.periodeObj, 'DD/MM/YYYY').toDate();
        var periodeObj = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        //let nb = new Date(periodeObj - moment(rowData.idPrest.debPrest, 'DD/MM/YYYY').toDate()).getMonth() + 1;
        return this.getMonthDifference(moment(rowData.idPrest.debPrest, 'DD/MM/YYYY').toDate(), periodeObj) + 1;
        //return nb;
    }

    getPrest(listeprest, idPrest) {
        return listeprest.filter(function (el) {
            return el.value === idPrest;
        })[0];
    }

    getSelectedData(liste, id) {
        return liste.filter(function (el) {
            return el.value === id;
        })[0];
    }



    wsurl() {
        return this.state.wsurl;
    }

    get_liste_regions() {
        return this.state.liste_region;
    }

    formaterValueSep(value) {
        return Numeral(parseFloat(value)).format('0,0');
    }

    get_userconnected() {
        return this.state.userconnected;
    }

    get_liste_mois() {
        return this.state.liste_mois;
    }

    get_mois(mois) {
        this.state.liste_mois.filter(function (el) {
            return el.value === mois;
        })
    }

    getBailleur() {
        let bailleur = '';
        if (this.state.userconnected !== null && this.state.userconnected !== undefined && this.state.userconnected !== '') {
            bailleur = this.state.userconnected.idPrest.idBailleur.libCodif;
        }
        return bailleur;
    }

    getProfil() {
        let profil = 'Consultation';
        if (this.state.userconnected !== null && this.state.userconnected !== undefined && this.state.userconnected !== '') {
            profil = this.state.userconnected.profilUser;
        }
        return profil;
    }






    get_tt_facture_trim(rannee, rTrim, rregion, rpref, rcrd, rtypePrest, ridBailleur) {
        var lien = this.wsurl() + '/etats/get_tt_facture_trim?rannee=' + rannee + '&rTrim=' + rTrim + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtypePrest=' + rtypePrest + '&ridBailleur=' + ridBailleur + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_tt_rapportage_trim(rannee, rTrim, rregion, rpref, rcrd, rtypePrest, ridPrest, ridBailleur) {
        var lien = this.wsurl() + '/etats/get_tt_rapportage_trim?rannee=' + rannee + '&rTrim=' + rTrim + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtypePrest=' + rtypePrest + '&ridPrest=' + ridPrest + '&ridBailleur=' + ridBailleur + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_stat_global_indicateur_clee_prest(spd1, spd2, rregion, rpref, rcrd, rlib, rtype_prest, rid_prest) {
        var lien = this.wsurl() + '/etats/get_stat_global_indicateur_clee_prest?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rlib=' + rlib + '&rtype_prest=' + rtype_prest + '&rid_prest=' + rid_prest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    getListe_stat_global_indicateur_clee_mois(spd1, spd2, rregion, rpref, rcrd, rlib, rtype_prest, rid_prest) {
        var lien = this.wsurl() + '/etats/getListe_stat_global_indicateur_clee_mois?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rlib=' + rlib + '&rtype_prest=' + rtype_prest + '&rid_prest=' + rid_prest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_dashboard_indicateurs(spd1, spd2, rregion, rpref, rcrd, rtype_prest, rid_prest) {
        var lien = this.wsurl() + '/etats/get_dashboard_indicateurs?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtype_prest=' + rtype_prest + '&rid_prest=' + rid_prest + '&iduser=' + this.state.userconnected.idUser;

        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    get_stat_rapportage_mois_prest(rannee, rregion, rpref, rcrd, rtypePrest, ridPrest, ridType) {
        var lien = this.wsurl() + '/etats/get_stat_rapportage_mois_prest?rannee=' + rannee + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    enreg_restriction(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idRestric == "0") {
            lien = this.wsurl() + '/restriction/add';
        } else {
            lien = this.wsurl() + '/restriction/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_restriction() {
        var lien = this.wsurl() + '/restriction/get_liste_restriction?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_restriction(data) {
        var lien = this.wsurl() + '/restriction/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }





    enreg_resultat_enquete(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idResEnq == "0") {
            lien = this.wsurl() + '/resultat_enquete/add_batch';
        } else {
            lien = this.wsurl() + '/resultat_enquete/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_resultat_enquete(spd1, spd2, ridPrest) {
        var lien = this.wsurl() + '/resultat_enquete/get_liste_resultat_enquete?pd1=' + spd1 + '&pd2=' + spd2 + '&ridPrest=' + ridPrest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_resultat_enquete(data) {
        var lien = this.wsurl() + '/resultat_enquete/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    enreg_resultat_enquete_batch(data) {
        var lien = this.wsurl() + '/ligne_resultat_enquete/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_resultat_enquete(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idRlenq == "0") {
            lien = this.wsurl() + '/ligne_resultat_enquete/add';
        } else {
            lien = this.wsurl() + '/ligne_resultat_enquete/update';
            methode = 'PUT';
        }
        alert(lien);
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_ligne_resultat_enquete(ridEnq) {
        var lien = this.wsurl() + '/ligne_resultat_enquete/get_liste_ligne_resultat_enquete?ridEnq=' + ridEnq;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_ligne_resultat_enquete(data) {
        var lien = this.wsurl() + '/ligne_resultat_enquete/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }








    get_stat_conso_budget(spd1, spd2, rregion, rpref, rcrd, rtypePrest, ridPrest, ridType) {
        var lien = this.wsurl() + '/etats/get_stat_conso_budget?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtypePrest=' + rtypePrest + '&ridPrest=' + ridPrest + '&ridType=' + ridType + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    enreg_depense(data) {
        var methode = 'POST';
        var lien = '';
        if (data.iddep === '0') {
            lien = this.wsurl() + '/depense/add';
            data.idEtabl = this.state.userconnected.idPrest.idPrestParent;
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/depense/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_depense(spd1, spd2, rtype_dep, rid_prest) {
        var lien = this.wsurl() + '/depense/liste_depense?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_prest=' + rid_prest + '&rtype_dep=' + rtype_dep + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            }).then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }


    delete_depense(data) {
        var methode = 'DELETE';
        var lien = this.wsurl() + '/depense/delete';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_stat_tendance_indicateur(spd1, spd2, rregion, rtypePrest, ridType) {
        var lien = this.wsurl() + '/etats/get_stat_tendance_indicateur?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rtypePrest=' + rtypePrest + '&ridType=' + ridType + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_stat_quantite_indicateur(spd1, spd2, rregion, rpref, rcrd, rtypePrest, ridPrest, ridType) {
        var lien = this.wsurl() + '/etats/get_stat_quantite_indicateur?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtypePrest=' + rtypePrest + '&ridPrest=' + ridPrest + '&ridType=' + ridType + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_stat_quantite_prest(spd1, spd2, rregion, rpref, rcrd, rtypePrest, ridPrest, rlib) {
        var lien = this.wsurl() + '/etats/get_stat_quantite_prest?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtypePrest=' + rtypePrest + '&ridPrest=' + ridPrest + '&rlib=' + rlib + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_key_value(ridType) {
        var lien = this.wsurl() + '/keyvalue/get_liste_keyvalue?ridType=' + ridType;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_key_value(data) {
        var lien = this.wsurl() + '/keyvalue/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_key_value(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idItem == "0") {
            lien = this.wsurl() + '/keyvalue/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.wsurl() + '/keyvalue/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }




    get_liste_ligne_enquete_saisie(ridPrest, rIdFamille) {
        var lien = this.wsurl() + '/ligne_enquete/get_liste_ligne_enquete_saisie?ridPrest=' + ridPrest + '&rIdFamille=' + rIdFamille;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_enquete_batch(data) {
        var lien = this.wsurl() + '/ligne_enquete/add_batch';
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_enquete(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idEnq == "0") {
            lien = this.wsurl() + '/ligne_enquete/add_batch';
        } else {
            lien = this.wsurl() + '/enquete/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_enquete(spd1, spd2, ridPrest, rlibEnq) {
        var lien = this.wsurl() + '/enquete/get_liste_enquete?pd1=' + spd1 + '&pd2=' + spd2 + '&ridPrest=' + ridPrest + '&rlibEnq=' + rlibEnq + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_enquete(data) {
        var lien = this.wsurl() + '/enquete/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }



    enreg_ligne_enquete_batch(data) {
        var lien = this.wsurl() + '/ligne_enquete/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_enquete(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idLenq == "0") {
            lien = this.wsurl() + '/ligne_enquete/add';
        } else {
            lien = this.wsurl() + '/ligne_enquete/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_ligne_enquete(ridEnq) {
        var lien = this.wsurl() + '/ligne_enquete/get_liste_ligne_enquete?ridEnq=' + ridEnq;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_ligne_enquete(data) {
        var lien = this.wsurl() + '/ligne_enquete/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




    download_fichier_joint(namefile, destination) {
        var lien = this.wsurl() + '/utils/download_fichier_joint?namefile=' + namefile + '&destination=' + destination + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    download_file(namefile, destination) {
        var lien = this.wsurl() + '/utils/download_file?namefile=' + namefile + '&destination=' + destination + '&iduser=' + this.state.userconnected.idUser;
        return lien
    }

    get_tt_facture_trim_prest(rannee, rregion, rpref, rcrd) {
        var lien = this.wsurl() + '/etats/get_tt_facture_trim_prest?rannee=' + rannee + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_tt_facture_trim_prest(rannee, rregion, rpref, rcrd, format) {
        var lien = this.wsurl() + '/etats/generer_tt_facture_trim_prest?rannee=' + rannee + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_facture_trim(idsPrest, rannee, rtrim) {
        var lien = this.wsurl() + '/etats/generer_rapportages_trim?idsPrest=' + idsPrest + '&rAnnee=' + rannee + '&rTrim=' + rtrim + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }


    enreg_gerer_site_bulk(data) {
        var lien = this.wsurl() + '/gerer_site/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_gerer_site(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idGererSite == 0) {
            lien = this.wsurl() + '/gerer_site/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/gerer_site/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_gerer_site() {
        var lien = this.wsurl() + '/gerer_site/get_all';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        })
            .then(data => {
                return data;
            }).catch((error) => {
                return error
            })
    }

    get_liste_gerer_site_user(idUser) {
        var lien = this.wsurl() + '/gerer_site/get_site_users?idUser=' + idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_param_type(ridType, rTypePrest) {
        var lien = this.wsurl() + '/param_type/get_liste_param_type?ridType=' + ridType + '&rTypePrest=' + rTypePrest;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_param_type(data) {
        var lien = this.wsurl() + '/param_type/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_param_type(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idObj == "0") {
            lien = this.wsurl() + '/param_type/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.wsurl() + '/param_type/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }


    upload_file(file, namefile, destination) {
        var methode = 'POST';
        var lien = this.wsurl() + '/utils/upload_file';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('iduser', this.state.userconnected.idUser);
        formData.append('namefile', namefile);
        formData.append('destination', destination);
        return fetch(lien, {
            method: methode,
            body: formData
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_dashboard(P_DATE1, P_DATE2, P_REGION) {
        var lien = this.wsurl() + '/etats/get_dashboard?P_DATE1=' + P_DATE1 + '&P_DATE2=' + P_DATE2 + '&P_REGION=' + P_REGION + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_stat_marge_indicateur(spd1, spd2, rregion, rpref, rcrd, rcategorie, rtypeacteur) {
        var lien = this.wsurl() + '/etats/get_stat_marge_indicateur?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rcategorie=' + rcategorie + '&rtypeacteur=' + rtypeacteur + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_stat_marge_indicateur(spd1, spd2, rregion, rpref, rcrd, rcategorie, rtypeacteur, format) {
        var lien = this.wsurl() + '/etats/generer_stat_marge_indicateur?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rcategorie=' + rcategorie + '&rtypeacteur=' + rtypeacteur + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }



    get_stat_marge_domaine(spd1, spd2, rregion, rpref, rcrd, rtypeacteur) {
        var lien = this.wsurl() + '/etats/get_stat_marge_domaine?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtypeacteur=' + rtypeacteur + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_stat_marge_domaine(spd1, spd2, rregion, rpref, rcrd, rtypeacteur, format) {
        var lien = this.wsurl() + '/etats/generer_stat_marge_domaine?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&rtypeacteur=' + rtypeacteur + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }




    get_stat_marge_prest(spd1, spd2, rregion, rpref, rcrd) {
        var lien = this.wsurl() + '/etats/get_stat_marge_prest?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_stat_marge_prest(spd1, spd2, rregion, rpref, rcrd, format) {
        var lien = this.wsurl() + '/etats/generer_stat_marge_prest?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }



    get_stat_rapportage_prest(spd1, spd2, rregion, rpref, rcrd) {
        var lien = this.wsurl() + '/etats/get_stat_rapportage_prest?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_stat_rapportage_prest(spd1, spd2, rregion, rpref, rcrd, format) {
        var lien = this.wsurl() + '/etats/generer_stat_rapportage_prest?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&rcrd=' + rcrd + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }


    get_stat_rapportage_pref(spd1, spd2, rregion) {
        var lien = this.wsurl() + '/etats/get_stat_rapportage_pref?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    generer_stat_rapportage_pref(spd1, spd2, rregion, format) {
        var lien = this.wsurl() + '/etats/generer_stat_rapportage_pref?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }


    get_stat_rapportage_region(spd1, spd2) {
        var lien = this.wsurl() + '/etats/get_stat_rapportage_region?pd1=' + spd1 + '&pd2=' + spd2 + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
    generer_stat_rapportage_region(spd1, spd2, format) {
        var lien = this.wsurl() + '/etats/generer_stat_rapportage_region?pd1=' + spd1 + '&pd2=' + spd2 + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    get_stat_rapportage_crd(spd1, spd2, rregion, rpref) {
        var lien = this.wsurl() + '/etats/get_stat_rapportage_crd?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_stat_rapportage_crd(spd1, spd2, rregion, rpref, format) {
        var lien = this.wsurl() + '/etats/generer_stat_rapportage_crd?pd1=' + spd1 + '&pd2=' + spd2 + '&rregion=' + rregion + '&rpref=' + rpref + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }



    generer_edition_rapportage(idsFacture, format) {
        var lien = this.wsurl() + '/etats/generer_rapportages?idsFacture=' + idsFacture + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_edition_facture(idsFacture, format) {
        var lien = this.wsurl() + '/etats/generer_factures?idsFacture=' + idsFacture + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }


    generer_edition_journal_facture(spd1, spd2, ridPrest, retatObj, rStatutPay, format) {
        var lien = this.wsurl() + '/etats/generer_journal_facture?pd1=' + spd1 + '&pd2=' + spd2 + '&retatObj=' + retatObj + '&ridPrest=' + ridPrest + '&rStatutPay=' + rStatutPay + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_edition_journal_rapportage(spd1, spd2, ridPrest, retatObj, rStatutPay, format) {
        var lien = this.wsurl() + '/etats/generer_journal_rapportage?pd1=' + spd1 + '&pd2=' + spd2 + '&retatObj=' + retatObj + '&ridPrest=' + ridPrest + '&rStatutPay=' + rStatutPay + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    generer_edition_journal_reco(spd1, spd2, ridPrest, retatRec, format) {
        var lien = this.wsurl() + '/etats/generer_journal_reco?pd1=' + spd1 + '&pd2=' + spd2 + '&retatRec=' + retatRec + '&ridPrest=' + ridPrest + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    get_journal_reco(spd1, spd2, ridPrest, retatRec) {
        var lien = this.wsurl() + '/etats/get_journal_reco?pd1=' + spd1 + '&pd2=' + spd2 + '&retatRec=' + retatRec + '&ridPrest=' + ridPrest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    generer_edition_journal_payement(spd1, spd2, ridPrest, ridObj, format) {
        var lien = this.wsurl() + '/etats/generer_journal_payement?pd1=' + spd1 + '&pd2=' + spd2 + '&ridObj=' + ridObj + '&ridPrest=' + ridPrest + '&format=' + format + '&iduser=' + this.state.userconnected.idUser;
        window.location = lien;
    }

    get_journal_payement(spd1, spd2, ridPrest, ridObj) {
        var lien = this.wsurl() + '/etats/get_journal_payement?pd1=' + spd1 + '&pd2=' + spd2 + '&ridObj=' + ridObj + '&ridPrest=' + ridPrest + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_recommandation(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idRec == "0") {
            lien = this.wsurl() + '/recommandation/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.wsurl() + '/recommandation/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_recommandation(spd1, spd2, ridObj, ridPrest, retatRec) {
        var lien = this.wsurl() + '/recommandation/get_liste_recommandation?ridObj=' + ridObj + '&pd1=' + spd1 + '&pd2=' + spd2 + '&retatRec=' + retatRec + '&ridPrest=' + ridPrest;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_reco_byRapportage(ridObj) {
        var lien = this.wsurl() + '/recommandation/get_liste_recommandation_by_rapportage?ridObj=' + ridObj;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_recommandation(data) {
        var lien = this.wsurl() + '/recommandation/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_prestataire(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idPrest == "0") {
            lien = this.wsurl() + '/prestataire/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.wsurl() + '/prestataire/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_fosa() {
        var lien = this.wsurl() + '/prestataire/get_liste_fosa?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_prestataire() {
        var lien = this.wsurl() + '/prestataire/get_liste_prestataire?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_prestataire_eval() {
        var lien = this.wsurl() + '/prestataire/get_liste_prestataire_eval?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_prestataire(data) {
        var lien = this.wsurl() + '/prestataire/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    update_param_eval_batch(data) {
        var lien = this.wsurl() + '/param_eval/update_batch';
        var methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_param_eval_batch(data) {
        var lien = this.wsurl() + '/param_eval/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_param_type(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idPtype == "0") {
            lien = this.wsurl() + '/param_type/add';
        } else {
            lien = this.wsurl() + '/param_type/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    enreg_param_eval(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idPeval == "0") {
            lien = this.wsurl() + '/param_eval/add';
        } else {
            lien = this.wsurl() + '/param_eval/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_param_eval(ridPrest, ridType) {
        var lien = this.wsurl() + '/param_eval/get_liste_param_eval?ridType=' + ridType + '&ridPrest=' + ridPrest;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_param_eval_forAdingPrest(rTypePrest, ridBailleur) {
        var lien = this.wsurl() + '/param_eval/get_liste_param_eval_forAdingPrest?rTypePrest=' + rTypePrest + '&ridBailleur=' + ridBailleur;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_param_eval_forAding(ridPrest, rTypePrest, ridBailleur) {
        var lien = this.wsurl() + '/param_eval/get_liste_param_eval_forAding?ridPrest=' + ridPrest + '&rTypePrest=' + rTypePrest + '&ridBailleur=' + ridBailleur;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_param_eval(data) {
        var lien = this.wsurl() + '/param_eval/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    valider_objectif_bloc(data, etatObj) {
        var lien = this.wsurl() + '/objectif/validation_bloc?etatObj=' + etatObj + '&iduser=' + this.state.userconnected.idUser;
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_objectif(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idObj == "0") {
            lien = this.wsurl() + '/objectif/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.wsurl() + '/objectif/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_objectif(spd1, spd2, ridPrest, retatObj, rStatutPay) {
        var lien = this.wsurl() + '/objectif/get_liste_objectif?pd1=' + spd1 + '&pd2=' + spd2 + '&retatObj=' + retatObj + '&ridPrest=' + ridPrest + '&rStatutPay=' + rStatutPay + '&iduser=' + this.state.userconnected.idUser;;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_objectif(data) {
        var lien = this.wsurl() + '/objectif/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_objectif_batch2(data) {
        var lien = this.wsurl() + '/ligne_objectif/add_batch_ligne';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_objectif_batch(data) {
        var lien = this.wsurl() + '/ligne_objectif/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_ligne_objectif_batch_trim(data) {
        var lien = this.wsurl() + '/ligne_objectif/add_batch_trim';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_ligne_objectif(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idLobj == "0") {
            lien = this.wsurl() + '/ligne_objectif/add';
        } else {
            lien = this.wsurl() + '/ligne_objectif/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_ligne_objectif(ridObj) {
        var lien = this.wsurl() + '/ligne_objectif/get_liste_ligne_objectif?ridObj=' + ridObj;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_typeserv_for_adding(ridPrest, rMois) {
        var lien = this.wsurl() + '/typeserv/get_liste_for_adding?ridPrest=' + ridPrest + '&rMois=' + rMois;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_famille_enquete(ridBailleur) {
        var lien = this.wsurl() + '/codification/get_liste_famille_enquete?ridBailleur=' + ridBailleur;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_ligne_objectif_for_adding_trim(ridPrest, rMois1, rMois2, rMois3) {
        var lien = this.wsurl() + '/ligne_objectif/get_liste_for_adding_trim?ridPrest=' + ridPrest + '&rMois1=' + rMois1 + '&rMois2=' + rMois2 + '&rMois3=' + rMois3;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_ligne_objectif_for_adding(ridPrest, rMois) {
        var lien = this.wsurl() + '/ligne_objectif/get_liste_for_adding?ridPrest=' + ridPrest + '&rMois=' + rMois;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_ligne_objectif_for_evaluation(ridPrest, rMois) {
        var lien = this.wsurl() + '/ligne_objectif/get_liste_for_evaluation?ridPrest=' + ridPrest + '&rMois=' + rMois + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_ligne_objectif_for_evaluation_trim(ridPrest, rMois1, rMois2, rMois3) {
        var lien = this.wsurl() + '/ligne_objectif/get_liste_for_evaluation_trim?ridPrest=' + ridPrest + '&rMois1=' + rMois1 + '&rMois2=' + rMois2 + '&rMois3=' + rMois3 + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    delete_ligne_objectif(data) {
        var lien = this.wsurl() + '/ligne_objectif/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_ligne_formule(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idLfeval == "0") {
            lien = this.wsurl() + '/ligne_formule/add';
        } else {
            lien = this.wsurl() + '/ligne_formule/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_ligne_formule(ridFeval) {
        var lien = this.wsurl() + '/ligne_formule/get_ligne_formule?ridFeval=' + ridFeval;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_ligne_formule(data) {
        var lien = this.wsurl() + '/ligne_formule/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_regler_objectif_bloc(data) {
        var lien = this.wsurl() + '/regler_objectif/add_batch';
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_regler_objectif(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idRgl == "0") {
            lien = this.wsurl() + '/regler_objectif/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.wsurl() + '/regler_objectif/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_regler_objectif(spd1, spd2, ridObj, ridPrest) {
        var lien = this.wsurl() + '/regler_objectif/get_liste_regler_objectif?pd1=' + spd1 + '&pd2=' + spd2 + '&ridObj=' + ridObj + '&ridPrest=' + ridPrest;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_regler_objectif(data) {
        var lien = this.wsurl() + '/regler_objectif/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_service_item(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idSrv == "0") {
            lien = this.wsurl() + '/service_item/add';
        } else {
            lien = this.wsurl() + '/service_item/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_service_item(ridType) {
        var lien = this.wsurl() + '/service_item/get_liste_service_item?ridType=' + ridType;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_service_item(data) {
        var lien = this.wsurl() + '/service_item/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_typeserv(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idType == "0") {
            lien = this.wsurl() + '/typeserv/add';
        } else {
            lien = this.wsurl() + '/typeserv/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_typeserv() {
        var lien = this.wsurl() + '/typeserv/get_liste_typeserv?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_typeserv_quantitatif() {
        var lien = this.wsurl() + '/typeserv/get_liste_indicateurs_quantitatif?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_typeserv(data) {
        var lien = this.wsurl() + '/typeserv/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_identification(loginUser, pwdUser) {
        var lien = this.wsurl() + '/user/get_identification';
        return fetch(lien, {
            method: 'POST', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({
                loginUser: loginUser,
                pwdUser: pwdUser
            })
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_user(data) {
        var lien = "";
        let methode = 'POST';
        if (data.idUser == "0") {
            lien = this.wsurl() + '/user/add';
            data.idEtabl = this.state.userconnected.idEtabl;
        } else {
            lien = this.wsurl() + '/user/update';
            methode = 'PUT';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })

    }

    get_liste_user() {
        var lien = this.wsurl() + '/user/get_liste_user?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_user(data) {
        var lien = this.wsurl() + '/user/delete';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_codif(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idCodif == '0') {
            lien = this.wsurl() + '/codification/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/codification/update';
        }
        data.iduser = this.state.userconnected.idUser;
        data.idBailleurRef = this.state.userconnected.idPrest.idBailleur.idCodif;
        data.codeBailleurRef = this.state.userconnected.idPrest.idBailleur.libCodif;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_codif(data) {
        var lien = this.wsurl() + '/codification/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_codif(typeCodif) {
        var lien = this.wsurl() + '/codification/get_liste_codif?typeCodif=' + typeCodif + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_site_batch(data) {
        var lien = this.wsurl() + '/sites/add_batch';
        var methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_site(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idSite == '0') {
            lien = this.wsurl() + '/sites/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/sites/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_site(data) {
        var lien = this.wsurl() + '/sites/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_sync_site() {
        var lien = this.wsurl() + '/sites/get_sync_site?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_site() {
        var lien = this.wsurl() + '/sites/get_liste_site?iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_fic_article(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idFic == '0') {
            lien = this.wsurl() + '/fic_article/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/fic_article/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_fic_article(data) {
        var lien = this.wsurl() + '/fic_article/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_fic_article(idArticle) {
        var lien = this.wsurl() + '/fic_article/get_liste_fic_article?idArticle=' + idArticle;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_fic_article_byAccess(rAccess) {
        var lien = this.wsurl() + '/fic_article/get_liste_fic_article_access?rAccess=' + rAccess;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_article(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idArticle == '0') {
            lien = this.wsurl() + '/article/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/article/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_article(data) {
        var lien = this.wsurl() + '/article/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_all_article() {
        var lien = this.wsurl() + '/article/get_liste_all_article';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_article(spd1, spd2) {
        var lien = this.wsurl() + '/article/get_liste_article?pd1=' + spd1 + '&pd2=' + spd2;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    enreg_formule(data) {
        var lien = '';
        var methode = 'POST';
        if (data.idFeval == '0') {
            lien = this.wsurl() + '/formule/add';
        } else {
            methode = 'PUT';
            lien = this.wsurl() + '/formule/update';
        }
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    delete_formule(data) {
        var lien = this.wsurl() + '/formule/delete';
        var methode = 'DELETE';
        data.iduser = this.state.userconnected.idUser;
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_formule() {
        var lien = this.wsurl() + '/formule/get_liste_formule';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_region() {
        var lien = this.wsurl() + '/etats/liste_region';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }


    get_liste_annee() {
        var lien = this.wsurl() + '/etats/liste_annee';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_prefecture() {
        var lien = this.wsurl() + '/etats/liste_prefecture';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_crd() {
        var lien = this.wsurl() + '/etats/liste_crd';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    enreg_sync_declaration(data) {
        var lien = this.wsurl() + '/ligne_objectif/enreg_sync_declaration?iduser=' + this.state.userconnected.idUser;
        var methode = 'PUT';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }
 
    enreg_sync_score(pe, option) {
        var lien = '';
        var methode = 'PUT';
        if (option == 'Global' || option == 'Subside') {
            lien = this.wsurl() + '/ligne_objectif/enreg_sync_score_global?pe=' + pe +'&option=' + option + '&iduser=' + this.state.userconnected.idUser;
        }else {
            lien = this.wsurl() + '/ligne_objectif/enreg_sync_score_domaine?pe=' + pe +'&iduser=' + this.state.userconnected.idUser;
        }
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_synthese_qualite_for_sync(rMois) {
        var lien = this.wsurl() + '/ligne_objectif/get_liste_synthese_qualite?rMois=' + rMois + '&iduser=' + this.state.userconnected.idUser;
        let methode = 'GET';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_single_hot_sync(pe, ridPrest) {
        var lien = this.wsurl() + '/ligne_objectif/single_hot_sync?pe=' + pe + '&ridPrest=' + ridPrest + '&iduser=' + this.state.userconnected.idUser;
        let methode = 'GET';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    hot_synch_indicateurs(pe) {
        var lien = this.wsurl() + '/ligne_objectif/hot_sync?pe=' + pe + '&iduser=' + this.state.userconnected.idUser;
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    analytic_synch_indicateurs(pe) {
        var lien = this.wsurl() + '/ligne_objectif/analytic_sync?pe=' + pe + '&iduser=' + this.state.userconnected.idUser;
        let methode = 'POST';
        return fetch(lien, {
            method: methode,
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_indicateurs_dataValues(period, orgUnit) {
        //var lien = 'https://entrepot.sante.gov.gn/dhis/api/30/dataValueSets?fields=:all&dataSet=lDvKWRd5Bhb&period=' + period + '&orgUnit=' + orgUnit;
        var lien = 'https://entrepot.sante.gov.gn/dev/dataValueSets?fields=:all&dataSet=lDvKWRd5Bhb&period=' + period + '&orgUnit=' + orgUnit;
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic a2FsaWwuY29uZGU6Sm9ic0AxMzA3'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_FBR_organisationUnit() {
        var lien = 'https://entrepot.sante.gov.gn/dev/api/organisationUnitGroups/i1H0GorUKr4.json';
        return fetch(lien, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Host': 'entrepot.sante.gov.gn',
                'Authorization': 'Basic aXNzb3VmOlB3ZGxzc0AxMzA3',
                'Accept': '*/*',
                // 'Authorization': 'ApiToken d2pat_BVoHdeDFBSrAhliSgDZtVKgp3kyX4ZEJ0480073742'
                //'Authorization': 'Basic base64encode(issouf:Pwdlss@1307)', aXNzb3VmOlB3ZGxzc0AxMzA3
                'Cache-Control': 'no-cache'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_Global_organisationUnit() {
        var lien = 'https://entrepot.sante.gov.gn/dev/api/organisationUnits.json?fields=id,name,level,parent,children,organisationUnitGroups,ancestors,geometry,code&paging=false';
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Host': 'entrepot.sante.gov.gn',
                'Authorization': 'Basic aXNzb3VmOlB3ZGxzc0AxMzA3',
                'Accept': '*/*',
                // 'Authorization': 'ApiToken d2pat_BVoHdeDFBSrAhliSgDZtVKgp3kyX4ZEJ0480073742'
                //'Authorization': 'Basic base64encode(issouf:Pwdlss@1307)',
                'Cache-Control': 'no-cache'
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_trace_rapportage(rref_trace) {
        var lien = this.wsurl() + '/trace/liste_trace_rapportage?rref_trace=' + rref_trace + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }

    get_liste_trace(spd1, spd2, rid_user, rref_trace, raction_trace, rcible_trace) {
        var lien = this.wsurl() + '/trace/liste_trace?pd1=' + spd1 + '&pd2=' + spd2 + '&rid_user=' + rid_user + '&rref_trace=' + rref_trace + '&raction_trace=' + raction_trace + '&rcible_trace=' + rcible_trace + '&iduser=' + this.state.userconnected.idUser;
        return fetch(lien, {
            method: 'GET', mode: "cors",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=UTF-8',
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        }).then(data => {
            return data;
        }).catch((error) => {
            return error
        })
    }




}