import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import 'moment';
import 'moment/locale/fr';
import moment from 'moment';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

export class FormReglerObjectif extends Component {
    constructor() {
        super();
        this.state = {
            liste_bailleur: [],
            listeobj: [],
            liste_selectedFacture: [],
            listeprest: [],
            listeprest_rech: [],
            liste_facture_rech: [{ value: 'Tous', label: 'Factures' }],
            listereglobj: [],
            selectedReglobj: null,
            selectedFacture: null,
            idRgl: 0,
            dateRgl: new Date(),
            montantRgl: 0,
            modeRgl: 'Espèce',
            liste_moderegl: [
                { label: 'Espèce', value: 'Espèce' },
                { label: 'Chèque', value: 'Chèque' },
                { label: 'Virement', value: 'Virement' }
            ],
            refRgl: '',
            idPrestBailleur: null,
            idObj: null,

            debut: new Date(),
            fin: new Date(),
            total: '0',
            rmodeRgl: '',
            rrefRgl: '',
            ridCodif: '',
            ridObj: '',
            ridPrest: '',
            msg: '',
            modif: false,
            showindicator: false,
            visible_dlg_rgl: false,
            visible: false
        };
        this.edit_reglobjectif = this.edit_reglobjectif.bind(this);
        this.conf_delete_reglobjectif = this.conf_delete_reglobjectif.bind(this);
        this.FbrService = new FbrService();
    }

    getData(id) {
        return this.state.liste_facture_rech.filter(function (el) {
            return el.value === id;
        })[0];
    }
    factureItemTemplate(option) {
        if (!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="clearfix">
                    <div className="grid">
                        <div style={{ fontWeight: 'bold' }} className="col-12 md:col-2">
                            <label>No {option.value}</label>
                        </div>
                        <div style={{}} className="col-12 md:col-2">
                            <label>{option.periodeObj}</label>
                        </div>
                        <div className="col-12 md:col-5">
                            <label>{option.nomPrest}</label>
                        </div>
                        <div className="col-12 md:col-3">
                            <label style={{ fontWeight: 'bold', color: 'red' }} >{option.mtRestObjFormat}</label>
                        </div>
                    </div>
                </div>
            );
        }
    }

    componentDidMount() {
        this.get_liste_reglobjectif();
        this.get_liste_factures();
        this.get_liste_facture_for_rgl();
        this.get_liste_prestataire();
        this.get_liste_codif();
    }


    get_liste_reglobjectif() {
        let spd1 = moment(this.state.debut).format('DD/MM/YYYY');
        let spd2 = moment(this.state.fin).format('DD/MM/YYYY');
        let ridpres = 'Tous';
        let ridob = 'Tous';
        if (this.state.ridPrest !== undefined || this.state.ridPrest !== null) {
            ridpres = this.state.ridPrest;
        }
        if (this.state.ridObj !== undefined || this.state.ridObj !== null) {
            ridob = this.state.ridObj;
        }
        this.setState({ showindicator: true });
        this.FbrService.get_liste_regler_objectif(spd1, spd2, ridob, ridpres).then(data => this.setState({ listereglobj: data, showindicator: false }));
    }



    get_liste_codif() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_codif('BAILLEUR').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_bailleur: liste });
            }
        });

    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        let liste_gnle = [{ value: 'Tous', label: 'Acteur' }];
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif }
                    });
                    this.setState({ listeprest: liste });
                    liste_gnle = liste_gnle.concat(liste);
                    this.setState({ listeprest_rech: liste_gnle });
                }
            }
        });
    }



    get_liste_facture_for_rgl() {
        this.FbrService.get_liste_objectif('Tous', 'Tous', 'Tous', 'Validé', 'Non payée').then(data => this.setState({ listeobj: data }));
    }

    get_liste_factures() {
        this.FbrService.get_liste_objectif('Tous', 'Tous', 'Tous', 'Tous', 'Tous').then(data => {
            if (data != null && data != undefined) {
                let liste = data.map(x => {
                    return { value: x.idObj, label: 'No ' + x.idObj + ' ' + x.periodeObj + ' ' + x.nomPrest, mtRestObj: x.mtRestObj }
                });
                let liste_facture_rech = [{ value: 'Tous', label: 'Factures', mtRestObj: 0 }].concat(liste);
                this.setState({ liste_facture_rech: liste_facture_rech })
            }
        });
    }

    resultat(code, contenu) {
        if (code == '200') {
            this.toast.show({ severity: 'success', summary: 'Success', detail: contenu });
            this.annule_reglobjectif();
            this.componentDidMount();
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: contenu });
        }
        this.setState({ showindicator: false });
    }

    edit_reglobjectif(data) {
        if (data != null && data != undefined) {
            this.setState({ idRgl: data.idRgl });
            this.setState({ dateRgl: new Date(moment(data.dateRgl, 'DD/MM/YYYY').format('YYYY-MM-DD')) });
            this.setState({ montantRgl: data.montantRgl });
            this.setState({ modeRgl: data.modeRgl });
            this.setState({ refRgl: data.refRgl });
            this.setState({ idPrestBailleur: data.idBailleur.idPrest });
            this.setState({ idObj: data.idObj.idObj });
            this.setState({ modif: true });
            this.setState({ selectedReglobj: data });
            this.setState({ selectedFacture: this.getData(data.idObj.idObj) })
        }
    }

    annule_reglobjectif() {
        this.setState({ dateRgl: '' });
        this.setState({ montantRgl: 0 });
        this.setState({ modeRgl: '' });
        this.setState({ refRgl: '' });
        this.setState({ total: '0' });
        this.setState({ idObj: 0 });
        this.setState({ idRgl: 0 });
        this.setState({ modif: false });
        this.forceUpdate();
    }

    enreg_reglobjectif_bloc() {
        if (this.state.dateRgl == "" || this.state.modeRgl == "" || this.state.idCodif == "" || this.state.idPrestBailleur == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else if (this.state.liste_selectedFacture == null && this.state.liste_selectedFacture == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures' });
        } else if (this.state.liste_selectedFacture.length <= 0) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner les factures' });
        } else {
            this.setState({ showindicator: true });
            let userconnected = this.FbrService.get_userconnected();
            let date_Rgl = moment(this.state.dateRgl, 'DD/MM/YYYY').format('DD/MM/YYYY');
            let liste_rgl = this.state.liste_selectedFacture.map(x => {
                return {
                    idRgl: 0,
                    dateRgl: date_Rgl,
                    montantRgl: x.mtRestObj,
                    modeRgl: this.state.modeRgl,
                    refRgl: this.state.refRgl,
                    idBailleur: { idPrest: this.state.idPrestBailleur },
                    idObj: { idObj: x.idObj, idPrest: { idPrest: x.idPrest } },
                    idEtabl: userconnected.idEtabl,
                    iduser: userconnected.idUser
                }
            });
            this.FbrService.enreg_regler_objectif_bloc(liste_rgl).then(data => this.resultat(data.code, data.contenu));
        }
    }


    enreg_reglobjectif() {
        if (this.state.dateRgl == "" || this.state.montantRgl == "" || this.state.modeRgl == "" || this.state.idPrestBailleur == "") {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez saisir tous les champs obligatoires' });
        } else {

            let date_Rgl = moment(this.state.dateRgl, 'DD/MM/YYYY').format('DD/MM/YYYY');
            if (this.state.modif == false) {
                if (parseFloat(this.state.selectedFacture.mtRestObj) < parseFloat(this.state.montantRgl)) {
                    this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                    return;
                }
                this.setState({ showindicator: true });
                let reglobjectif = {
                    idRgl: 0,
                    dateRgl: date_Rgl,
                    montantRgl: this.state.montantRgl,
                    modeRgl: this.state.modeRgl,
                    refRgl: this.state.refRgl,
                    idObj: { idObj: this.state.idObj }
                }
                this.FbrService.enreg_regler_objectif(reglobjectif).then(data => this.resultat(data.code, data.contenu));
            } else {
                if (parseFloat(this.state.selectedFacture.mtRestObj) <= 0) {
                    if (parseFloat(this.state.montantRgl) > parseFloat(this.state.selectedReglobj.montantRgl)) {
                        this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Le montant saisi est supérieur au restant' });
                        return;
                    }
                }
                this.setState({ showindicator: true });
                let reglobjectif = this.state.selectedReglobj;
                reglobjectif.dateRgl = date_Rgl;
                reglobjectif.montantRgl = this.state.montantRgl;
                reglobjectif.modeRgl = this.state.modeRgl;
                reglobjectif.refRgl = this.state.refRgl;
                this.FbrService.enreg_regler_objectif(reglobjectif).then(data => this.resultat(data.code, data.contenu));
            }
        }
    }

    actionTemplate(rowData) {
        return <div className="grid" style={{ width: 90 }}>
            <div className="col"><Button title='Modifier' onClick={() => this.edit_reglobjectif(rowData)} className="p-button-info" style={{ width: 25, height: 25 }} icon="pi pi-pencil" /></div>
            <div className="col"><Button title='Supprimer' onClick={() => this.conf_delete_reglobjectif(rowData)} className="p-button-danger" style={{ width: 25, height: 25, marginLeft: -20 }} icon="pi pi-times" /></div>
        </div>
    }


    delete_reglobjectif() {
        if (this.state.selectedReglobj == null || this.state.selectedReglobj == undefined) {
            this.toast.show({ severity: 'warn', summary: 'Attention', detail: 'Veuillez sélectionner la ligne à supprimer' });
        } else {
            this.setState({ showindicator: true });
            this.FbrService.delete_regler_objectif(this.state.selectedReglobj).then(data => this.resultat(data.code, data.contenu));
        }
    }


    conf_delete_reglobjectif(data) {
        if (data != null && data != undefined) {
            this.setState({ selectedReglobj: data });
            this.setState({ msg: data.montantRgl });
            this.setState({ visible: true });
        }
    }

    exportCSV = () => {
        this.dt.exportCSV();
    }


    render() {
        let liste_facture_for_rgl = null;
        if (this.state.listeobj != null && this.state.listeobj != undefined) {
            liste_facture_for_rgl = this.state.listeobj.map(x => {
                return { value: x.idObj, label: 'No ' + x.idObj + ' ' + x.periodeObj + ' ' + x.nomPrest, periodeObj: x.periodeObj, nomPrest: x.nomPrest, mtRestObj: x.mtRestObj, mtRestObjFormat: this.FbrService.formaterValueSep(parseFloat(x.mtRestObj)) }
            });
        }

        let header_export = <React.Fragment>
            {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />}
        </React.Fragment>

        let header_rgl = <Button label="Règlement en bloc" icon="pi pi-refresh" style={{ width: 180 }} className="p-button-success" onClick={(e) => this.setState({ visible_dlg_rgl: true }, () => {
            this.get_liste_facture_for_rgl();
        })} />


        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAL" colSpan={4} footerStyle={{ textAlign: 'center', fontWeight: 'bold' }} />
                <Column footer={this.state.total} style={{ color: 'red', fontWeight: 'bold' }} />
            </Row>
        </ColumnGroup>;

        const dialogFooter = (
            <div>
                <Button icon="pi pi-check" onClick={() => {
                    this.setState({ visible: false });
                    this.delete_reglobjectif();
                }} label="Oui" />
                <Button icon="pi pi-times" onClick={() => this.setState({ visible: false })} label="Annuler" className="p-button-secondary" />
            </div>
        );

        return (
            <div className="fluid">
                <Toast ref={(el) => this.toast = el} />
                <Dialog header="Confirmation" visible={this.state.visible} style={{ width: '250px' }} modal={true} footer={dialogFooter} onHide={() => this.setState({ visible: false })}>
                    <div>
                        <i className="fa fa-warning" style={{ color: '#FFD133', fontSize: 25 }}></i>
                        Voulez vous vraiment supprimer <b>{this.state.msg} </b>?
                    </div>
                </Dialog>
                <Dialog visible={this.state.visible_dlg_rgl} style={{ width: '80%' }} header="Règlement en bloc" modal className="fluid" onHide={(e) => this.setState({ visible_dlg_rgl: false })}>
                    <div className="card" style={{ height: 450, marginTop: -20 }}>
                        <DataTable value={this.state.listeobj} footerColumnGroup={footerGroup}
                            responsive={true} scrollable className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" 
                            onSelectionChange={e => this.setState({ liste_selectedFacture: e.value }, () => {
                                let total = 0;
                                if (this.state.liste_selectedFacture != null && this.state.liste_selectedFacture != undefined) {
                                    if (this.state.liste_selectedFacture.length > 0) {
                                        for (var i = this.state.liste_selectedFacture.length; i--;) {
                                            total += this.state.liste_selectedFacture[i].mtRestObj;
                                        }
                                    }
                                }
                                this.setState({ total: this.FbrService.formaterValueSep(parseFloat(total)) });
                            })
                            }  selection={this.state.liste_selectedFacture} scrollHeight="200px">
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                            <Column field="idObj" filter={true} filterMatchMode='contains' header="N° facture" sortable={true} style={{ width: '12%' }} />
                            <Column field="periodeObj" filter={true} filterMatchMode='contains' header="Période" sortable={true} style={{ width: '10%' }} />
                            <Column field="nomPrest" filter={true} filterMatchMode='contains' header="Acteur" sortable={true} />
                            <Column field="trimestreObj" filter={true} filterMatchMode='contains' header="Trimestre" style={{ width: '12%' }} sortable={true} />
                            <Column field="mtRestObj" body={(rowData, column) => {
                                return <div>
                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.mtRestObj))}</span>
                                </div>
                            }} filter={true} filterMatchMode='contains' header="Restant" sortable={true} />
                        </DataTable>
                    </div>

                    <div className="grid" style={{marginTop: -30}}>

                        <div className="col-12 md:col-4">
                            <label htmlFor="dateRgl">Date *</label>
                            <Calendar id="dateRgl" dateFormat="dd/mm/yy" value={this.state.dateRgl} onChange={(e) => this.setState({ dateRgl: e.target.value })} appendTo={document.body} />
                        </div>

                        <div className="col-12 md:col-4">
                            <label htmlFor="modeRgl">Mode règlement</label>
                            <Dropdown value={this.state.modeRgl} options={this.state.liste_moderegl} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ modeRgl: e.target.value })} autoWidth={false} appendTo={document.body} />
                        </div>

                        <div className="col-12 md:col-4">
                            <label htmlFor="refRgl">Référence</label>
                            <InputText id="refRgl" value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                        </div>
                    </div>
                    <center>
                        <div className="grid" style={{ width: 300 }}>
                            <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => {
                                this.setState({ visible_dlg_rgl: false, liste_selectedFacture: [] });
                            }} /></div>
                            <div className="col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_reglobjectif_bloc()} /></div>
                        </div>
                        <br />
                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                    </center>
                </Dialog>


                <div className="grid">
                    <div className="col-12">
                        <div className="card card-w-title">

                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <label htmlFor="dateRgl">Date *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Calendar id="dateRgl" dateFormat="dd/mm/yy" value={this.state.dateRgl} onChange={(e) => this.setState({ dateRgl: e.target.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="idObj">Facture *</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown value={this.state.idObj} options={liste_facture_for_rgl} optionLabel='label' dataKey='value' filter={true} filterBy='value,label,nomPrest' filterMatchMode='contains' onChange={(e) => this.setState({ idObj: e.target.value, selectedFacture: this.getData(e.value) }, () => {
                                        this.setState({ montantRgl: this.state.selectedFacture.mtRestObj });
                                    })} autoWidth={false} itemTemplate={this.factureItemTemplate} panelStyle={{ width: '500px' }} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="montantRgl">Montant *</label>
                                </div>
                                <div className="col-6 md:col-4">
                                    <InputNumber value={this.state.montantRgl} onChange={(e) => this.setState({ montantRgl: e.value })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="modeRgl">Mode règlement</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <Dropdown value={this.state.modeRgl} options={this.state.liste_moderegl} optionLabel='label' filter={true} filterBy='label' filterMatchMode='contains' onChange={(e) => this.setState({ modeRgl: e.target.value })} autoWidth={false} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <label htmlFor="refRgl">Référence</label>
                                </div>
                                <div className="col-12 md:col-4">
                                    <InputText id="refRgl" value={this.state.refRgl} onChange={(e) => this.setState({ refRgl: e.target.value })} />
                                </div>


                            </div>
                            <center>
                                <div className="grid" style={{ width: 300 }}>
                                    <div className="col"><Button label="Annuler" icon="pi pi-ban" onClick={() => this.annule_reglobjectif()} /></div>
                                    <div className="col"><Button label="Valider" icon="pi pi-check" onClick={() => this.enreg_reglobjectif()} /></div>
                                </div>
                                <br />
                                {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                            </center>

                            <div className="grid">

                                <div className="col-12">
                                    <div className="card card-w-title">
                                        <i className="pi pi-search"></i> Option de recherche
                                        <hr />
                                        <div className="grid">
                                            <div className="col-2 md:col-2">
                                                <Calendar value={this.state.debut} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ debut: e.value }, () => {
                                                    this.get_liste_reglobjectif();
                                                })} />
                                            </div>
                                            <div className="col-2 md:col-2">
                                                <Calendar value={this.state.fin} dateFormat="dd/mm/yy" onChange={(e) => this.setState({ fin: e.value }, () => {
                                                    this.get_liste_reglobjectif();
                                                })} />
                                            </div>
                                            <div className="col-2 md:col-3">
                                                <Dropdown autoWidth={false} options={this.state.liste_facture_rech} optionLabel='label' placeholder='Factures' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridObj} onChange={e => this.setState({ ridObj: e.value }, () => {
                                                    this.get_liste_reglobjectif();
                                                })} panelStyle={{ width: '500px' }} />
                                            </div>
                                            <div className="col-2 md:col-3">
                                                <Dropdown autoWidth={false} options={this.state.listeprest} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridCodif} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                                    this.get_liste_reglobjectif();
                                                })} panelStyle={{ width: '500px' }} />
                                            </div>
                                            <div className='col-2 md:col-2'>
                                                <Button icon='pi pi-search' label='Rechercher' onClick={() => this.get_liste_reglobjectif()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <DataTable value={this.state.listereglobj} paginatorPosition="top" selectionMode="single" paginator={true} rows={20} paginatorLeft={header_rgl} paginatorRight={header_export}
                                alwaysShowPaginator={true} onRowClick={e => this.setState({ selectedReglobj: e.data })} onRowSelect={e => this.setState({ selectedReglobj: e.data })}
                                responsive={true} selection={this.state.selectedReglobj} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers"  ref={(el) => { this.dt = el; }}>
                                <Column header="Action" body={this.actionTemplate.bind(this)} style={{ width: '7%' }} />
                                <Column field="idRgl" header="No reçu" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="dateRgl" header="Date" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="idObj.idObj" header="No facture" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                                 <Column field="idObj.moisObj" header="Mois" body={(rowData, column) => {
                                    let periodeObj = rowData.idObj.moisObj + '/' + rowData.idObj.anneeObj;
                                    periodeObj = periodeObj.padStart(7, "0");
                                    return <div>
                                        <span>{periodeObj}</span><br />
                                    </div>
                                }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                                 <Column field="idObj.idPrest.nomPrest" header="Acteur"  filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="montantRgl" header="Montant" body={(rowData, column) => {
                                    return <div>
                                        <span style={{ color: 'green', fontWeight: 'bold' }}>{this.FbrService.formaterValueSep(parseFloat(rowData.montantRgl))}</span>
                                    </div>
                                }} sortable={true} style={{ width: '10%' }} filter={true} filterMatchMode='contains'  />
                                <Column field="modeRgl" header="Mode" style={{ width: '10%' }} filter={true} filterMatchMode='contains' sortable={true} />
                                <Column field="refRgl" header="Référence" style={{ width: '15%' }} filter={true} filterMatchMode='contains' sortable={true} />
                            </DataTable>
                        </div> 
                    </div>
                </div>
            </div>
        );
    }
}