import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { ColumnGroup } from 'primereact/columngroup';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar'; 
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';

export class FormJournalFacture extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(moment(new Date()).format('YYYY') + '-01-01'),
            pd2: new Date(),
            ridPrest: null,
            retatObj: null,
            rstatutPay: null,
            liste_data: [],
            liste_selectedFacture: [],
            listeprest: [],
            totalFacture: '0',
            totalRest: '0',
            totalQual: '0',
            showindicator: false,
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.exportExcel();
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                },
                {
                    label: 'Editer factures',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print_factures();
                    }
                }
            ],
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };

        this.conf_print = this.conf_print.bind(this);
        this.conf_print_factures = this.conf_print_factures.bind(this);
        this.FbrService = new FbrService();
    }
  
    conf_print_facture_trim(rowData) {
        this.FbrService.generer_facture_trim(rowData.idPrest,rowData.anneeObj,rowData.trimestreObj);
    }
    
    conf_print_factures() {
        let ids = "";
        if (this.state.liste_selectedFacture !== null && this.state.liste_selectedFacture !== undefined && this.state.liste_selectedFacture.length > 0) {
            ids = this.state.liste_selectedFacture.map(x => {
                return x.idObj
            }).join("_");
        } else {
            ids = this.state.liste_data.map(x => {
                return x.idObj
            }).join("_");
        }
        this.FbrService.generer_edition_rapportage(ids, 'PDF');
    }

    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let ridpres = 'Tous';
        let retat = 'Tous';
        let rstatut = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            ridpres = this.state.ridPrest;
        }
        if (this.state.retatObj !== undefined && this.state.retatObj !== null) {
            retat = this.state.retatObj;
        }
        if (this.state.rstatutPay !== undefined && this.state.rstatutPay !== null) {
            retat = this.state.rstatutPay;
        }
        this.FbrService.generer_edition_journal_facture(spd1, spd2, ridpres, retat, rstatut, format);
    }

    get_liste_prestataire() {
        this.setState({ showindicator: true });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if(data!==null && data!==undefined && data!==''){
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest }
                    });
                    this.setState({ listeprest: liste });
                }
            }
            
            
        });
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let ridpres = 'Tous';
        let retat = 'Tous';
        let rstatut = 'Tous';
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            ridpres = this.state.ridPrest;
        }
        if (this.state.retatObj !== undefined && this.state.retatObj !== null) {
            retat = this.state.retatObj;
        }
        if (this.state.rstatutPay !== undefined && this.state.rstatutPay !== null) {
            retat = this.state.rstatutPay;
        }
        this.setState({ showindicator: true });
        this.FbrService.get_liste_objectif(spd1, spd2, ridpres, retat, rstatut).then(data => this.setState({ liste_data: data }, () => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined) {
                if (data.length > 0) {
                    let initialValue = 0;
                    let tt = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mttObj
                    }, initialValue);
                    let tr = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mtRestObj
                    }, initialValue);
                    let tq = data.reduce(function (accumulator, currentValue) {
                        return accumulator + currentValue.mtQualObj
                    }, initialValue);
                    this.setState({ totalFacture: this.FbrService.formaterValueSep(tt) });
                    this.setState({ totalRest: this.FbrService.formaterValueSep(tr) });
                    this.setState({ totalQual: this.FbrService.formaterValueSep(tq) });
                }
            }
        }));
    }

     /* Exportation du tableau  en fichier XLSX */
     exportExcel = () => {
        let liste_data = this.state.liste_data.length > 0 ? this.state.liste_data.map(x => {
            return { 'ID': x.idObj, 'PERIODE': x.periodeObj,'ETAT': x.etatObj, 'STATUT': x.statutPayObj, 'PRESTATAIRE': x.nomPrest,'TYPE': x.typePrest, 'CATEGORIE': x.categoriePrest , 'STRUCTURE': x.nomPrestParent , 'IRS': x.regionSite, 'DISTRICT': x.prefectureSite,'CRD': x.crdSite, 'BONUS': x.mtQualObj, 'SUBSIDE': x.mttObj, 'PAYE': x.mtRglObj, 'RESTANT': x.mtRestObj, 'SCORE': x.scoreObj, 'TD': x.margeError, 'MOIS': x.moisObj, 'ANNEE': x.anneeObj, 'MT_QUANTITE': x.mtQuantObj, 'MT_BRUT': x.mtQuantBrutObj, 'MALUSE': x.mtQuantBrutObj, 'TRIMESTRE': x.trimestreObj    }
        }) : [];
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(liste_data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'JOURNAL_FACTURE_');
        });
    }

    /* Enregistrer le fichier sur le client */
    saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }

    componentDidMount() {
        this.get_liste_prestataire();
        this.get_liste_data();
    }


    render() {

        let footerGroup = <ColumnGroup>
            <Row>
                <Column footer="TOTAUX" colSpan={7} footerStyle={{ textAlign: 'center',fontWeight: 'bold' }} />
                <Column footer={this.state.totalQual} footerStyle={{fontWeight: 'bold' }} />
                <Column footer={this.state.totalFacture} footerStyle={{fontWeight: 'bold' }} />
                <Column footer={this.state.totalRest} footerStyle={{fontWeight: 'bold',color: 'red' }} />
            </Row>
        </ColumnGroup>;

        return (
            <div className="card p-fluid">

                <div className="grid">
                    <div className="col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                                    <hr />
                            <div className="grid">
                                <div className="col-12 md:col-2">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown autoWidth={false} options={[{ label: 'Acteur', value: 'Tous' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                        this.get_liste_data();
                                    })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown autoWidth={false} options={
                                        [{ label: 'Etat', value: 'Tous' },
                                        { label: 'En attente', value: 'En attente' },
                                        { label: 'Validé', value: 'Validé' }
                                        ]} optionLabel='label' placeholder='Etat' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.retatObj} onChange={e => this.setState({ retatObj: e.value }, () => {
                                            this.get_liste_data();
                                        })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <Dropdown autoWidth={false} options={
                                        [{ label: 'Payement', value: 'Tous' },
                                        { label: 'Payée', value: 'Payée' },
                                        { label: 'Non', value: 'Non payée' }
                                        ]} optionLabel='label' placeholder='Statut payement' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.retatObj} onChange={e => this.setState({ retatObj: e.value }, () => {
                                            this.get_liste_data();
                                        })} />
                                </div>
                                <div className="col-12 md:col-2">
                                    <div className="inputgroup">
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <DataTable value={this.state.liste_data} selection={this.state.liste_selectedFacture} onSelectionChange={e => this.setState({ liste_selectedFacture: e.value })}
                    responsive={true} scrollable={true} footerColumnGroup={footerGroup}  scrollHeight="620px" csvSeparator=";"  ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                    <Column field="idObj" header="No" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                    <Column field="periodeObj" header="Date" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '8%' }} />
                    <Column field="statutPayObj" header="Statut" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '9%' }} />
                    <Column field="nomPrest" header="Acteur" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="regionSite" header="IRS" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '10%' }} />
                    <Column field="prefectureSite" header="DPS" filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="mtQualObj" header="Mt qualité" body={(rowData, column) => {
                        return <div>
                            <span  >{this.FbrService.formaterValueSep(parseFloat(rowData.mtQualObj))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '11%' }} />
                    <Column field="mttObj" header="Mt à payer" body={(rowData, column) => {
                        return <div>
                            <span  >{this.FbrService.formaterValueSep(parseFloat(rowData.mttObj))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                    <Column field="mtRestObj" header="Restant" body={(rowData, column) => {
                        return <div>
                            <span style={{ color: 'red' }} >{this.FbrService.formaterValueSep(parseFloat(rowData.mtRestObj))}</span>
                        </div>
                    }} filter={true} filterMatchMode='contains' sortable={true} style={{ width: '12%' }} />
                </DataTable>
            </div>
        );
    }
}