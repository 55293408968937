import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';
import { Chart } from 'primereact/chart';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';

const chartOptions = {
    legend: {
        display: true,
    },
    responsive: true,
    hover: {
        mode: 'index',
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    min: 0
                }
            }
        ]
    }
};
const barchartOptions = {
    legend: {
        labels: {
            fontColor: '#495057'
        }
    },
    scales: {
        xAxes: [{
            ticks: {
                fontColor: '#495057',
                min: 0,
            }
        }],
        yAxes: [{
            ticks: {
                fontColor: '#495057'
            }
        }]
    }
};
export class DashboardIndicateurs extends Component {
    constructor() {
        super();
        this.state = {
            pd1: new Date(moment('01/01/' + moment(new Date()).format('YYYY'), 'YYYY-MM-DD').format('YYYY-MM-DD')),
            pd2: new Date(),
            rRegion: null,
            rPref: null,
            rCrd: null,
            liste_stat_global_indicateur_clee: [],
            liste_stat_global_indicateur_clee_mois: [],
            liste_stat_global_indicateur_clee_prest: [],
            liste_region: [],
            liste_all_pref: [],
            liste_pref: [],
            liste_all_crd: [],
            liste_crd: [],
            rlib: 'Tous',
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
            itemSelected: 'NA',
            liste_type_prest: [],
            listeprest: [],
            listeprest_all: [],
            rtypePrest: null,
            ridPrest: null,

        };
        this.FbrService = new FbrService();
        this.itemTemplate = this.itemTemplate.bind(this);
    }

    refresh_dashboard_indicateurs() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        let id_prest = 'Tous';
        let type_prest = 'Tous';
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
        if (this.state.rtypePrest !== undefined && this.state.rtypePrest !== null) {
            type_prest = this.state.rtypePrest;
        }
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            id_prest = this.state.ridPrest;
        }
        this.setState({ showindicator: true });
        this.FbrService.getListe_stat_global_indicateur_clee_mois(spd1, spd2, region, pref, crd, this.state.rlib, type_prest, id_prest).then(data => {
            this.setState({
                liste_stat_global_indicateur_clee_mois: data,
                showindicator: false
            });
        });
        this.setState({ showindicator: true });
        this.FbrService.get_stat_global_indicateur_clee_prest(spd1, spd2, region, pref, crd, this.state.rlib, type_prest, id_prest).then(data => {
            this.setState({
                liste_stat_global_indicateur_clee_prest: data,
                showindicator: false
            });
        });
    }


    get_dashboard_indicateurs() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        let id_prest = 'Tous';
        let type_prest = 'Tous';
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
        if (this.state.rtypePrest !== undefined && this.state.rtypePrest !== null) {
            type_prest = this.state.rtypePrest;
        }
        if (this.state.ridPrest !== undefined && this.state.ridPrest !== null) {
            id_prest = this.state.ridPrest;
        }
        this.setState({ showindicator: true, itemSelected: 'NA' });
        this.FbrService.get_dashboard_indicateurs(spd1, spd2, region, pref, crd, type_prest, id_prest).then(data => {
            this.setState({
                liste_stat_global_indicateur_clee: data.liste_stat_global_indicateur_clee,
                liste_stat_global_indicateur_clee_prest: data.liste_stat_global_indicateur_clee_prest,
                liste_stat_global_indicateur_clee_mois: data.liste_stat_global_indicateur_clee_mois,
                showindicator: false
            });
        });
    }

    componentDidMount() {
        this.FbrService.get_liste_prefecture().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_pref: data });
            }
        });
        this.FbrService.get_liste_crd().then(data => {
            if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
            }
        });
        this.get_dashboard_indicateurs();
        this.FbrService.get_liste_codif('TYPE_PRESTATAIRE').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                }).filter(function (el) {
                    return (el.label == 'RECO-ASC' || el.label == 'PS' || el.label == 'Hopital' || el.label == 'CS' || el.label == 'DPS' || el.label == 'DRS');
                });
                this.setState({ liste_type_prest: liste });
            }
        });
        this.setState({ liste_region: this.FbrService.get_liste_regions() });
        this.FbrService.get_liste_prestataire_eval().then(data => {
            this.setState({ showindicator: false });
            if (data !== null && data !== undefined && data !== '') {
                if (data.length > 0) {
                    let liste = data.map(x => {
                        return { value: x.idPrest, label: x.nomPrest, typePrest: x.typePrest.libCodif, idTypePrest: x.typePrest.idCodif, regionSite: x.idSite.regionSite, prefectureSite: x.idSite.prefectureSite, crdSite: x.idSite.crdSite }
                    }).filter(function (el) {
                        return (el.typePrest == 'RECO-ASC' || el.typePrest == 'PS' || el.typePrest == 'Hopital' || el.typePrest == 'CS' || el.typePrest == 'DPS' || el.typePrest == 'DRS');
                    });
                    this.setState({ listeprest: liste, listeprest_all: liste });
                }
            }
        });
    }


    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        this.setState({ liste_pref: liste });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        this.setState({ liste_crd: liste });
    }

    select_acteurs() {
        let liste = this.state.listeprest_all;
        if (this.state.listeprest_all != null && this.state.listeprest_all != undefined) {
            if (this.state.listeprest_all.length > 0) {
                let region = this.state.rRegion == null ? 'Tous' : this.state.rRegion;
                let pref = this.state.rPref == null ? 'Tous' : this.state.rPref;
                let crd = this.state.rCrd == null ? 'Tous' : this.state.rCrd;
                let type_prest = this.state.rtypePrest == null ? 'Tous' : this.state.rtypePrest;

                if (region !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.regionSite == region;
                    });
                }
                if (pref !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.prefectureSite == pref;
                    });
                }
                if (crd !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.crdSite == crd;
                    });
                }
                if (type_prest !== 'Tous') {
                    liste = liste.filter(function (el) {
                        return el.idTypePrest == type_prest;
                    });
                }

            }
        }
        this.setState({ listeprest: liste });
    }

    itemTemplate(domaine) {
        if (!domaine) {
            return;
        }
        return this.renderGridItem(domaine);
    }

    getColor(lib) {
        if (lib == 'CONTRES-REFERENCES') {
            return '#cbf3db';
        } else if (lib == 'ACCOUCHEMENT') {
            return '#f6e6d9';
        } else if (lib == 'CPN4') {
            return '#c6eaf9';
        } else if (lib == 'CPoN') {
            return '#f6f9c6';
        } else if (lib == 'PF') {
            return '#fcbad7';
        } else {
            return '#dfe0db';
        }
    }

    getBorderColor(lib) {
        if (lib == 'CONTRES-REFERENCES') {
            return '#65b083';
        } else if (lib == 'ACCOUCHEMENT') {
            return '#daad89';
        } else if (lib == 'CPN4') {
            return '#66b1d1';
        } else if (lib == 'CPoN') {
            return '#c5c893';
        } else if (lib == 'PF') {
            return '#d58cac';
        } else {
            return '#adaeab';
        }
    }

    renderGridItem(domaine) {
        return (
            <div style={{ padding: '.5em' }} className={this.FbrService.getBailleur()=='KFW'?"col-12 md:col-3":"col-12 md:col-4"}>
                <a onClick={() => {
                    this.setState({ rlib: domaine.libLobj, itemSelected: domaine.libLobj }, () => {
                        this.refresh_dashboard_indicateurs();
                    })
                }}>
                    <div style={{ textAlign: 'center', backgroundColor: this.getColor(domaine.libLobj), height: '85px', borderBottomWidth: '4px', borderBottomColor: this.state.itemSelected == domaine.libLobj ? this.getBorderColor(domaine.libLobj) : this.getColor(domaine.libLobj), borderBottomStyle: 'solid' }} >
                        <div>
                            <div style={{
                                display:  'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <span >
                                    <img src={"assets/layout/images/" + domaine.libLobj + ".png"} style={{ width: 35, height: 35, marginTop: 5 }} /> 
                                </span>
                                <label style={{ fontSize: '1.1em', color: "#000" }}>{domaine.libLobj} </label>
                            </div>
                        </div>
                        <label style={{ fontSize: '1.7em', color: "#000", fontWeight: 'bold' }}> {domaine.valConfLobj}</label>
                    </div>
                </a>
            </div>
        );
    }

    render() {
        let liste_label = null;
        let liste_ds_qd = null;
        let liste_ds_qv = null;
        let liste_ds_ca = null;
        let liste_ds_snis = null;
        if (this.state.liste_stat_global_indicateur_clee_mois != null && this.state.liste_stat_global_indicateur_clee_mois != undefined) {
            if (this.state.liste_stat_global_indicateur_clee_mois.length > 0) {
                liste_label = this.state.liste_stat_global_indicateur_clee_mois.map(x => {
                    return x.moisLobj
                });
                liste_ds_qd = this.state.liste_stat_global_indicateur_clee_mois.map(x => {
                    return x.valEvalLobj
                });
                liste_ds_qv = this.state.liste_stat_global_indicateur_clee_mois.map(x => {
                    return x.valConfLobj
                });
                liste_ds_ca = this.state.liste_stat_global_indicateur_clee_mois.map(x => {
                    return x.valPrevLobj
                });
                liste_ds_snis = this.state.liste_stat_global_indicateur_clee_mois.map(x => {
                    return x.valDhis2Lobj
                });
            }
        }


        let liste_label_prest = null;
        let liste_ds_qv_prest = null;
        let liste_ds_ca_prest = null;
        if (this.state.liste_stat_global_indicateur_clee_prest != null && this.state.liste_stat_global_indicateur_clee_prest != undefined) {
            if (this.state.liste_stat_global_indicateur_clee_prest.length > 0) {
                liste_label_prest = this.state.liste_stat_global_indicateur_clee_prest.map(x => {
                    return x.nomPrest
                });
                liste_ds_qv_prest = this.state.liste_stat_global_indicateur_clee_prest.map(x => {
                    return x.valConfLobj
                });
                liste_ds_ca_prest = this.state.liste_stat_global_indicateur_clee_prest.map(x => {
                    return x.valPrevLobj
                });

            }
        }

        const prestChart = {
            labels: liste_label_prest,
            datasets: [
                {
                    label: 'Prévues',
                    backgroundColor: '#78909C',
                    data: liste_ds_ca_prest
                },
                {
                    label: 'Validées',
                    backgroundColor: '#66BB6A',
                    data: liste_ds_qv_prest
                },
            ]
        }

        const chart_data = {
            labels: liste_label,
            datasets: [
                {
                    label: 'Quantités déclarées',
                    backgroundColor: '#4071a7',
                    borderColor: '#4071a7',
                    fill: false,
                    data: liste_ds_qd
                },
                {
                    label: 'Quantités validées',
                    backgroundColor: '#66BB6A',
                    borderColor: '#66BB6A',
                    fill: false,
                    data: liste_ds_qv
                },
                {
                    label: 'Quantités SNIS',
                    backgroundColor: '#784179',
                    borderColor: '#784179',
                    fill: false,
                    data: liste_ds_snis
                },
                {
                    label: 'Cible attendue',
                    backgroundColor: '#F04D0C',
                    borderColor: '#F04D0C',
                    fill: false,
                    data: liste_ds_ca
                }
            ]
        };

        return (
            <div className="layout-dashboard card p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                            <hr />
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_dashboard_indicateurs();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_dashboard_indicateurs();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'Region', value: 'Tous' }].concat(this.state.liste_region)} optionLabel='label' placeholder='Region' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rRegion} onChange={e => this.setState({ rRegion: e.value }, () => {
                                        this.get_dashboard_indicateurs();
                                        this.select_pref(e.value);
                                        this.select_acteurs();
                                    })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'Préfecture', value: 'Tous' }].concat(this.state.liste_pref)} optionLabel='label' placeholder='Préfecture' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rPref} onChange={e => this.setState({ rPref: e.value }, () => {
                                        this.get_dashboard_indicateurs();
                                        this.setState({ liste_crd: [] });
                                        this.select_crd(e.value);
                                        this.select_acteurs();
                                    })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'CR', value: 'Tous' }].concat(this.state.liste_crd)} optionLabel='label' placeholder='CR' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rCrd} onChange={e => this.setState({ rCrd: e.value }, () => {
                                        this.get_dashboard_indicateurs();
                                        this.select_acteurs();
                                    })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'Type Acteur', value: 'Tous' }].concat(this.state.liste_type_prest)} optionLabel='label' placeholder='Type Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rtypePrest} onChange={e => this.setState({ rtypePrest: e.value }, () => {
                                        this.get_dashboard_indicateurs();
                                        this.select_acteurs();
                                    })} />
                                </div>

                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ value: 'Tous', label: 'Acteur' }].concat(this.state.listeprest)} optionLabel='label' placeholder='Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.ridPrest} onChange={e => this.setState({ ridPrest: e.value }, () => {
                                        this.get_dashboard_indicateurs();
                                    })} panelStyle={{ width: '500px' }} />
                                </div>

                                <div className="col-12 md:col-2">
                                    <Button label="Rechercher" style={{ width: '155px', marginBottom: 5 }} onClick={(e) => this.get_dashboard_indicateurs()} icon="pi pi-search" model={this.state.items} />
                                </div>
                                <div className="col-12 md:col-1">
                                    {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-12 lg-12" style={{ marginTop: -30 }}>
                    <DataView value={this.state.liste_stat_global_indicateur_clee} layout='grid'
                        itemTemplate={this.itemTemplate}
                    />
                </div>
                <div className="card" style={{ height: 50, marginTop: -10, backgroundColor: '#F7F7F7' }}>
                    <label style={{ fontSize: 20, fontWeight: 'bold' }}>{this.state.rlib == 'Tous' ? 'STAT GLOBALE' : "STATS " + this.state.rlib}</label>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <i className="pi pi-chart-bar"></i> Statistiques des consommations par acteur
                        <hr />
                        <Chart type="bar" data={prestChart} options={barchartOptions} height="100w" />
                    </div>
                    <div className="col-12">
                        <i className="pi pi-chart-bar"></i> Statistiques des tendances par mois
                        <hr />
                        <Chart options={chartOptions} responsive={true} type="line" data={chart_data} height="100w" />
                    </div>
                </div>

            </div>
        );
    }
}