import React, { Component } from 'react';
import FbrService from '../service/FbrService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { SplitButton } from 'primereact/splitbutton';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'moment/locale/fr';
import moment from 'moment';

export class FormStatMargeDomaine extends Component {
    constructor() {
        super();
        this.state = {
            pd1:  new Date(moment(new Date()).format('YYYY') + '-01-01'),
            pd2: new Date(),
            rRegion: null,
            rPref: null,
            rCrd: null,
            liste_data: [],
            liste_region: [],
            liste_all_pref: [],
            liste_pref: [],
            liste_all_crd: [],
            liste_crd: [],
            liste_type_prest: [],
            rtypePrest: null,
            rcategorie: 'Qualitative',
            items: [
                {
                    label: 'Format PDF',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('PDF');
                    }
                },
                {
                    label: 'Format XLS',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.conf_print('XLS');
                    }
                },
                {
                    label: 'Format CSV',
                    icon: 'pi pi-print',
                    command: (e) => {
                        this.dt.exportCSV();
                    }
                }
            ],
            showindicator: false,
            userconnected: JSON.parse(localStorage.getItem('userconnected')),
        };
        this.conf_print = this.conf_print.bind(this);
        this.FbrService = new FbrService();
    }


    conf_print(format) {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        let rtypeacteur = 'Tous';
        if (this.state.rtypePrest !== undefined && this.state.rtypePrest !== null) {
            rtypeacteur = this.state.rtypePrest;
        }
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
        this.FbrService.generer_stat_marge_domaine(spd1, spd2, region, pref,crd,rtypeacteur, format);
    }

    get_liste_data() {
        let spd1 = moment(this.state.pd1).format('MM/YYYY');
        let spd2 = moment(this.state.pd2).format('MM/YYYY');
        let region = 'Tous';
        let pref = 'Tous';
        let crd = 'Tous';
        let rtypeacteur = 'Tous';
        if (this.state.rtypePrest !== undefined && this.state.rtypePrest !== null) {
            rtypeacteur = this.state.rtypePrest;
        }
        if (this.state.rCrd !== undefined && this.state.rCrd !== null) {
            crd = this.state.rCrd;
        }
        if (this.state.rRegion !== undefined && this.state.rRegion !== null) {
            region = this.state.rRegion;
        }
        if (this.state.rPref !== undefined && this.state.rPref !== null) {
            pref = this.state.rPref;
        }
       
        this.setState({showindicator: true});
        this.FbrService.get_stat_marge_domaine(spd1, spd2, region, pref,crd,rtypeacteur).then(data => this.setState({ liste_data: data,showindicator: false }));
    }

    componentDidMount() {
        this.FbrService.get_liste_prefecture().then(data => {
			if (data != null && data != undefined) {
                this.setState({liste_all_pref: data});
			} 
		});
        this.FbrService.get_liste_crd().then(data => {
			if (data != null && data != undefined) {
                this.setState({ liste_all_crd: data });
			} 
		});
        this.FbrService.get_liste_codif('TYPE_PRESTATAIRE').then(data => {
            this.setState({ showindicator: false });
            if (data.length > 0) {
                let liste = data.map(x => {
                    return { value: x.idCodif, label: x.libCodif }
                });
                this.setState({ liste_type_prest: liste });
            }
        });
        this.get_liste_data();
        this.setState({liste_region: this.FbrService.get_liste_regions()});
    }


    select_pref(region) {
        let liste = [];
        if (this.state.liste_all_pref != null && this.state.liste_all_pref != undefined) {
            if (this.state.liste_all_pref.length > 0) {
                let liste_filtre = this.state.liste_all_pref.filter(function (el) {
                    return el.region == region;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_pref, label: x.nom_pref }
                        });
                    }
                }
            }
        }
        this.setState({ liste_pref: liste });
    }

    select_crd(pref) {
        let liste = [];
        if (this.state.liste_all_crd != null && this.state.liste_all_crd != undefined) {
            if (this.state.liste_all_crd.length > 0) {
                let liste_filtre = this.state.liste_all_crd.filter(function (el) {
                    return el.nom_pref == pref;
                });
                if (liste_filtre != null && liste_filtre != undefined) {
                    if (liste_filtre.length > 0) {
                        liste = liste_filtre.map(x => {
                            return { value: x.nom_crd, label: x.nom_crd }
                        });
                    }
                }
            }
        }
        this.setState({ liste_crd: liste });
    }


    render() {
        return (
            <div className="card p-fluid">
                <div className="grid">
                    <div className="col-12">
                        <div className="card-w-title">
                            <i className="pi pi-search"></i> Option de recherche
                                    <hr />
                            <div className="grid">
                                <div className="col-12 md:col-3">
                                    <Calendar value={this.state.pd1} onChange={(e) => this.setState({ pd1: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Calendar value={this.state.pd2} onChange={(e) => this.setState({ pd2: e.value }, () => {
                                        this.get_liste_data();
                                    })} view="month" dateFormat="mm/yy" yearNavigator yearRange="2021:2070" />
                                </div>
                                <div className="col-2 md:col-3">
                                    <Dropdown autoWidth={false} options={this.state.liste_type_prest} optionLabel='label' placeholder='Type Acteur' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rtypePrest} onChange={e => this.setState({ rtypePrest: e.value }, () => {
                                        this.get_liste_data();
                                    })} />
                                </div>
                                
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'IRS', value: 'Tous' }].concat(this.state.liste_region)} optionLabel='label' placeholder='IRS' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rRegion} onChange={e => this.setState({ rRegion: e.value }, () => {
                                        this.get_liste_data();
                                        this.select_pref(e.value);
                                    })} />
                                </div> 
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'DPS', value: 'Tous' }].concat(this.state.liste_pref)} optionLabel='label' placeholder='DPS' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rPref} onChange={e => this.setState({ rPref: e.value }, () => {
                                        this.get_liste_data();
                                        this.setState({liste_crd: []});
                                        this.select_crd(e.value);
                                    })} />
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown autoWidth={false} options={[{ label: 'CR', value: 'Tous' }].concat(this.state.liste_crd)} optionLabel='label' placeholder='CR' filter={true} filterBy='label' filterMatchMode='contains' value={this.state.rCrd} onChange={e => this.setState({ rCrd: e.value }, () => {
                                        this.get_liste_data();
                                    })} />

                                </div>
                                <div className="col-12 md:col-3">
                                    <div className="inputgroup">
                                        <SplitButton label="Rechercher" style={{ width: '140px' }} onClick={(e) => this.get_liste_data()} icon="pi pi-search" model={this.state.items}></SplitButton>
                                        {this.state.showindicator ? <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="4" fill="#EEEEEE" animationDuration=".5s" /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                <DataTable value={this.state.liste_data} responsive={true} scrollable={true} scrollHeight="620px" csvSeparator=";"  ref={(el) => { this.dt = el; }} className="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-customers" >
                    <Column field="ordre" header="#" style={{ width: '6%' }} filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="indicateur" header="DOMAINE" filter={true} filterMatchMode='contains' sortable={true} />
                    <Column field="margeError" header="TD" style={{ width: '8%' }} filter={true} filterMatchMode='contains' sortable={true} />
                </DataTable>
            </div>
        );
    }
}